import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/img/bi/logo.png'


const _hoisted_1 = { class: "d-flex jc-center title_wrap" }
const _hoisted_2 = { class: "d-flex jc-center" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "timers flex flex-ai-c" }
const _hoisted_5 = { class: "fs-14" }
const _hoisted_6 = { class: "t1 fs-12" }

import { reactive, computed } from "vue";
import dayjs from 'dayjs';
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'header',
  props: {
    buttonName: {
        type: String,
        default: '退出全屏'
    }
},
  emits: ['click'],
  setup(__props, { emit: __emit }) {

const companyName = computed(() => {
    return store.state.companyName
})

const props = __props

const dateData = reactive({
    dateDay: "",
    dateYear: "",
    dateWeek: "",
    timing: null as any
});
const emits = __emit

const weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
const timeFn = () => {
    dateData.timing = setInterval(() => {
        dateData.dateYear = dayjs().format("YYYY-MM-DD")
        dateData.dateDay = dayjs().format("HH : mm : ss");
        dateData.dateWeek = weekday[dayjs().day()];
    }, 1000);
};
timeFn()

const handleButtonClick = () => {
    emits('click')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: "",
      class: "logo"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["title-text fm", { 'set-size': companyName.value?.length >= 16, 'set-size2': companyName.value?.length >= 24, 'set-size3': companyName.value?.length >= 26, 'set-size4': companyName.value?.length >= 28, 'set-size5': companyName.value?.length >= 30 }])
        }, _toDisplayString(companyName.value), 3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(dateData.dateYear) + " " + _toDisplayString(dateData.dateWeek) + " " + _toDisplayString(dateData.dateDay), 1),
      _createElementVNode("div", {
        class: "button mg-l-10 pointer",
        onClick: handleButtonClick
      }, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(__props.buttonName), 1)
      ])
    ])
  ]))
}
}

})