import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/img/bi/default-img.png'


const _hoisted_1 = {
  key: 0,
  class: "left_boottom"
}
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = { class: "flex-2" }
const _hoisted_4 = { class: "flex-1 fc-lightgreen" }
const _hoisted_5 = {
  key: 1,
  class: "left_boottom flex flex-jc-c flex-ai-c flex-column set-mg-t"
}

import { computed, onMounted, reactive, ref } from "vue";
import boxContainer from '@/components/box-container/index.vue';
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import { chunk } from 'lodash';
import { serveCompanyInfos } from '@/api/bi.ts';
import { filterName, filterCompanyName } from '@/utils/util.ts';


export default /*@__PURE__*/_defineComponent({
  __name: 'right-bottom',
  setup(__props, { expose: __expose }) {

const state = reactive<any>({
  list: [],
  pageList: [],
  visibleList: [],
  pageNum: 0,
  pageSize: 50,
  maxPageNum: 0,
  scroll: true,
  total: 0
});

let loading = ref(false)

const getData = () => {
  loading.value = true
  serveCompanyInfos({}, {
    loading: true
  }).then((res: any) => {
    loading.value = false
    state.list = res || []
    state.total = res?.length || 0
    state.maxPageNum = Math.ceil(state.list.length / state.pageSize)
    state.pageList = chunk(state.list, state.pageSize)
    state.visibleList = state.pageList[state.pageNum]
  })
};

const getCount = (count: any) => {
  state.visibleList = state.pageList[count % state.maxPageNum]
  if (state.pageList?.length > 1 && state.visibleList?.length < state.pageSize) {
    const temp = state.pageList[0].slice(0, state.pageSize - state.visibleList?.length)
    state.visibleList = [...state.visibleList, ...temp]
  }
}
__expose({
  getData
})
onMounted(() => {
  getData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(boxContainer, {
    title: `合作企业(${state.total}家)`,
    loading: _unref(loading),
    height: 210
  }, {
    header: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "left_boottom" }, [
        _createElementVNode("div", { class: "left_boottom_item set-pd" }, [
          _createElementVNode("div", { class: "flex w-full" }, [
            _createElementVNode("div", { class: "flex-2" }, "客户"),
            _createElementVNode("div", { class: "flex-1" }, "入职人数")
          ])
        ])
      ], -1)
    ])),
    default: _withCtx(() => [
      _createVNode(_unref(Vue3SeamlessScroll), {
        list: state.visibleList,
        limitScrollNum: 5,
        singleHeight: 43,
        singleWaitTime: 2000,
        class: "scroll",
        onCount: getCount
      }, {
        default: _withCtx(() => [
          (state.visibleList?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.visibleList, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["left_boottom_item", { 'strip': i % 2 === 0 }]),
                    key: i
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(filterCompanyName)(item.serveCompanyName)), 1),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(item.pwCount), 1)
                    ])
                  ], 2))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1),
                _createElementVNode("div", { class: "fs-12 fc-default mg-t-5" }, "暂无数据", -1)
              ])))
        ]),
        _: 1
      }, 8, ["list"])
    ]),
    _: 1
  }, 8, ["title", "loading"]))
}
}

})