<template>
    <div class="flex gap-24 pd-32 top-box">
        <template v-for="(item, index) in data" :key="index">
            <topItem class="flex-1" :item-data="item"/>
        </template>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import topItem from '../component/top-item.vue';
import { headInfo } from '@/api/bi.ts';
const data = ref({
    allWorkerCntRes: {
        title: '累计入职',
        value: 0,
        prefix: '',
        suffix: '',
    },
    protectCntRes: {
        title: '当前在保(日保+长期)',
        // subProp: 'projectCoverageRate',
        // subTitle: '日保覆盖率',
        value: 0,
        // subValue: 0,
        prefix: '',
        suffix: '',
        subPrefix: '',
        // subSuffix: '%'
    },
    totalPayRes: {
        title: '总发薪量',
        value: 0,
        prefix: '¥',
        suffix: '',
        subPrefix: '',
        subSuffix: ''
    },
    totalGetRes: {
        title: '总领薪量',
        value: 0,
        prefix: '¥',
        suffix: '',
        subPrefix: '',
        subSuffix: ''
    },
    totalApplyRes: {
        title: '累计预支',
        value: 0,
        prefix: '¥',
        suffix: '',
        subPrefix: '',
        subSuffix: ''
    },
    eleConsumeAmountRes: {
        title: '合同签署数',
        value: 0,
        prefix: '',
        suffix: '份',
        subPrefix: '',
        subSuffix: ''
    }
})
const getData = () => {
    headInfo({}, {
        loading: true
    }).then(res => {
        if (res) {
            for(let key in data.value) {
                data.value[key].value = 0
                setTimeout(() => {
                    data.value[key].value = res[key]
                }, 0);
                if(data.value[key].subProp) {
                    data.value[key].subValue = 0
                    setTimeout(() => {
                        data.value[key].subValue = res[data.value[key].subProp]
                    }, 0);
                }
            }
        }
    })
}
defineExpose({
    getData
})
onMounted(() => {
    getData()
})
</script>
<style lang="stylus" scoped>
.gap-24 {
    gap: 24px
}
.pd-32 {
    padding: 0 32px
}
</style>