import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content-box flex flex-ai-c" }
const _hoisted_2 = { class: "img-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-jc-sb flex-1" }
const _hoisted_6 = { class: "item" }
const _hoisted_7 = { class: "t1 fc-orange fs-18" }
const _hoisted_8 = { class: "t1 fs-18" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "t3 bold fs-18 mg-t-10" }
const _hoisted_11 = { class: "item mg-r-20" }
const _hoisted_12 = { class: "t3 bold fs-18 mg-t-10" }

import { computed, onMounted, ref } from "vue";
import boxContainer from '@/components/box-container/index.vue';
import { monthRank } from '@/api/bi.ts';


export default /*@__PURE__*/_defineComponent({
  __name: 'right-rank',
  setup(__props, { expose: __expose }) {

let data = ref([]) as any
let carlRef = ref(null) as any
let loading = ref(false)
const getData = () => {
  loading.value = true
  monthRank({}, {
    loading: true
  }).then((res: any) => {
    loading.value = false
    if (res?.length) {
      data.value = res.slice(0, 3)
      setTimeout(() => {
        carlRef.value.setActiveItem(0)
      }, 0);
    }
  })
}

__expose({
  getData
})

onMounted(() => {
  getData()
});

return (_ctx: any,_cache: any) => {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!

  return (_openBlock(), _createBlock(boxContainer, {
    title: "月度之星",
    loading: _unref(loading),
    height: 125
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_carousel, {
        ref_key: "carlRef",
        ref: carlRef,
        height: "125",
        interval: 15000,
        direction: "vertical",
        arrow: "never",
        "indicator-position": "none"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (item, index) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    (item.userHeadUrl)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: _normalizeClass(["icon-head", [`icon-${index+1}`]]),
                          src: item.userHeadUrl,
                          alt: ""
                        }, null, 10, _hoisted_3))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          class: _normalizeClass(["icon-head", [`icon-${index+1}`]]),
                          src: "https://bullet001.oss-cn-shanghai.aliyuncs.com/file-saas-img/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.png",
                          alt: ""
                        }, null, 2)),
                    _createElementVNode("img", {
                      src: require(`../../../assets/img/bi/top-${index+1}.png`),
                      alt: "",
                      class: "icon-crown"
                    }, null, 8, _hoisted_4)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, "NO." + _toDisplayString(index+1), 1),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(item.userName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "t2 fs-12" }, "项目数", -1)),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(item.projectCount), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "t2 fs-12" }, "服务人数", -1)),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(item.serviceCount), 1)
                    ])
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["loading"]))
}
}

})