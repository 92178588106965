import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../assets/img/bi/default-img.png'


const _hoisted_1 = {
  key: 0,
  class: "left_boottom"
}
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = { class: "w-65" }
const _hoisted_4 = { class: "w-65" }
const _hoisted_5 = { class: "flex-1 single-line" }
const _hoisted_6 = {
  key: 1,
  class: "left_boottom flex flex-jc-c flex-ai-c flex-column set-mg-t"
}

import { computed, onMounted, ref, reactive } from "vue";
import boxContainer from '@/components/box-container/index.vue';
import carousel from './carousel.vue';
import { chunk } from 'lodash';
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import { filterName, filterCompanyName } from '@/utils/util.ts';

import { scrollIngMsg, workerInfos } from '@/api/bi.ts';


export default /*@__PURE__*/_defineComponent({
  __name: 'left-top',
  setup(__props, { expose: __expose }) {

let scrollData = ref([]) as any

const getScrollData = () => {
  scrollIngMsg({}, {
    loading: true
  }).then((res) => {
    scrollData.value = res || []
    console.log(3444, scrollData.value);
  })
}

let loading = ref(false)

const state = reactive<any>({
  list: [],
  pageList: [],
  visibleList: [],
  pageNum: 0,
  pageSize: 50,
  maxPageNum: 0,
  scroll: true,
});

const getData = () => {
  loading.value = true
  getScrollData()
  workerInfos({}, {
    loading: true
  }).then((res: any) => {
    loading.value = false
    if (res?.length) {
      state.list = res
      state.maxPageNum = Math.ceil(state.list.length / state.pageSize)
      state.pageList = chunk(state.list, state.pageSize)
      state.visibleList = state.pageList[state.pageNum]
    }
  })
};

const getCount = (count: any) => {
  state.visibleList = state.pageList[count % state.maxPageNum]
  if (state.pageList?.length > 1 && state.visibleList?.length < state.pageSize) {
    const temp = state.pageList[0].slice(0, state.pageSize - state.visibleList?.length)
    state.visibleList = [...state.visibleList, ...temp]
  }
}

__expose({
  getData
})
onMounted(() => {
  getData()
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(boxContainer, {
    height: 271,
    loading: _unref(loading)
  }, {
    header: _withCtx(() => [
      (_unref(scrollData)?.length)
        ? (_openBlock(), _createBlock(carousel, {
            key: 0,
            data: _unref(scrollData)
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "left_boottom set-bg" }, [
        _createElementVNode("div", { class: "left_boottom_item" }, [
          _createElementVNode("div", { class: "flex w-full" }, [
            _createElementVNode("div", { class: "w-65" }, "时间"),
            _createElementVNode("div", { class: "w-65" }, "职工姓名"),
            _createElementVNode("div", { class: "flex-1" }, "用工企业"),
            _createElementVNode("div", { class: "w-100" }, "状态")
          ])
        ])
      ], -1))
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(Vue3SeamlessScroll), {
        list: state.visibleList,
        limitScrollNum: 6,
        singleHeight: 43,
        singleWaitTime: 2000,
        class: "scroll",
        onCount: getCount
      }, {
        default: _withCtx(() => [
          (state.visibleList?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.visibleList, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["left_boottom_item", { 'strip': i % 2 === 0 }]),
                    key: i
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(item.time), 1),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(filterName)(item.userName)), 1),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(filterCompanyName)(item.serverCompanyName)), 1),
                      _createElementVNode("div", {
                        class: _normalizeClass(["w-100 fc-green", { 'fc-blue': (item.type === 2 && item.content === '保障中') }])
                      }, _toDisplayString(item.content), 3)
                    ])
                  ], 2))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: ""
                }, null, -1),
                _createElementVNode("div", { class: "fs-12 fc-default mg-t-5" }, "暂无数据", -1)
              ])))
        ]),
        _: 1
      }, 8, ["list"])
    ]),
    _: 1
  }, 8, ["loading"]))
}
}

})