<template>
    <div class="flex flex-ai-c flex-jc-c">
        <img :src="require(`../../../assets/img/${itemData.src}`)" class="icon-img" alt="">
        <div class="right-box">
            <div class="title-box">
                <div class="title">{{ itemData.title }}</div>
            </div>
            <div class="value-box fs-18 mg-t-10 fm-youshe">
                <span class="prefix">{{ itemData.prefix }}</span>
                <span class="value">
                    <CountTo :startVal="0" :endVal="itemData.value" :decimals="decimal(itemData.value)" :duration="3000"/>
                </span>
                <span class="suffix">{{ itemData.suffix }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { CountTo } from 'vue3-count-to';

const props = defineProps({
    itemData: {
        type: Object,
        default() {
            return {}
        }
    }
})

const decimal = computed(() => {
    return (number) => {
        if (number) {
            const num = number.toString().split('.')[1]
            return num?.length ?? 0
        }
        return 0
    }
})
// const getAssetUrl = (url) => {
//     return new URL(`../../../assets/${url}`, import.meta.url).href
// }
</script>
<style lang="stylus" scoped>
.icon-img {
    width 89px
    height 97px
}
.title-box {
    padding 3px 30px 3px 10px 
    background linear-gradient(90deg, #0099FF 8%, rgba(0, 144, 255, 0) 92%)
    transform skew(-30deg)
}
.title {
    transform skew(30deg)
}
.value-box {
    padding-left 10px
    background linear-gradient(180deg, #FFFFFF 25%, #9BE5FF 57%, #0DCAF5 73%)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
    background-clip text
    text-fill-color transparent
    font-weight bold
}
@font-face {
    font-family: youSheBiaoTiHei;
    src: url('../../../assets/font/youSheBiaoTiHei.ttf') format('truetype');
}
.fm-youshe {
    font-family youSheBiaoTiHei
}
</style>