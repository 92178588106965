<template>
    <div class="top-item">
        <div class="top-container">
            <div class="flex flex-ai-c mg-b-5" :class="{'flex-jc-sb': itemData.subTitle, 'flex-jc-c': !itemData.subTitle}">
                <div class="flex flex-ai-c">
                    <img src="../../../assets/img/bi/icon-triangle.png" alt="" class="icon-triangle">
                    <div class="fs-16 t1">{{ itemData.title }}</div>
                </div>
                <div class="fs-12 t1">{{ itemData.subTitle }}</div>
            </div>
            <div class="border mg-b-5" :class="{'center': !itemData.subTitle}"></div>
            <div class="flex flex-ai-c" :class="{'flex-jc-sb': itemData.subValue || itemData.subValue === 0, 'flex-jc-c': !itemData.subValue}">
                <div class="fs-30 t2 fm-youshe">
                    <span class="prefix">{{ itemData.prefix }}</span>
                    <CountTo :startVal="0" :endVal="itemData.value" separator="" :decimals="decimal(itemData.value)" :duration="3000"/>
                    <span class="suffix">{{ itemData.suffix }}</span>
                </div>
                <div class="fs-18 t3 fm-youshe">
                    <span class="prefix">{{ itemData.subPrefix }}</span>
                    <template v-if="itemData.subValue || itemData.subValue === 0">
                        <CountTo :startVal="0" :endVal="itemData.subValue" :decimals="decimal(itemData.subValue)" :duration="3000"/>
                    </template>
                    <span class="prefix">{{ itemData.subSuffix }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import itemWrap from '@/components/border-box/item-wrap.vue';
import { CountTo } from 'vue3-count-to';
const props = defineProps({
    itemData: {
        type: Object,
        default() {
            return {}
        }
    }
})

const decimal = computed(() => {
    return (number) => {
        if (number) {
            const num = number.toString().split('.')[1]
            return num?.length ?? 0
        }
        return 0
    }
})

</script>
<style lang="stylus" scoped>
.top-item {
    color #fff
    font-size 16px
    // padding-bottom 6px
    background url('../../../assets/img/bi/item-bg.png') no-repeat
    background-size 100% 100%
    // background: linear-gradient(270deg, rgba(0, 205, 250, 0) 0%, rgba(1, 200, 248, 0.2106) 52%, rgba(1, 191, 243, 0) 100%);

}
.top-container {
    padding 15px 20px 10px
}
.border {
    width 68%
    height 1px
    background #00FFFD
    position relative
    &::after {
        content ''
        width 4px
        height 4px
        border-radius 2px
        background #00FFFD
        position absolute
        right 0
        top -2px
    }
}
.center {
    margin 0 auto
}
.fs-38 {
    font-size 38px
}
.t1 {
    text-shadow: 0px 0px 6px rgba(30, 198, 255, 0.8);
}
.t2 {
    background: linear-gradient(180deg, #FFFFFF 25%, #9BE5FF 57%, #0DCAF5 73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.t3 {
    color #21FFB5
}
.pd-b-20 {
    padding-bottom 20px
}
@font-face {
    font-family: youSheBiaoTiHei;
    src: url('../../../assets/font/youSheBiaoTiHei.ttf') format('truetype');
}
.fm-youshe {
    font-family youSheBiaoTiHei
}
.icon-triangle {
    width 12px
    height 12px
    margin-right 8px
}
</style>