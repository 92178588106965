<template>
  <boxContainer title="职工年龄比例" :height="340">
    <div class="left_boottom_wrap beautify-scroll-def">
      <div class="left_boottom">
        <div ref="chartRef" id="main" style="width: 460px; height:350px;"></div>
      </div>
    </div>
  </boxContainer>
</template>
<script setup >
import { ref, onMounted, reactive } from "vue"
import * as echarts from 'echarts'
import 'echarts-gl';
import boxContainer from '@/components/box-container/index.vue'
import { ageAnalyse } from '@/api/bi.ts';

const chartRef = ref(null)
let myChart = null
// 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
function getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, height) {
  // 计算
  let midRatio = (startRatio + endRatio) / 2;
  let startRadian = startRatio * Math.PI * 2;
  let endRadian = endRatio * Math.PI * 2;
  let midRadian = midRatio * Math.PI * 2;
  // 如果只有一个扇形，则不实现选中效果。
  if (startRatio === 0 && endRatio === 1) {
    isSelected = false;
  }
  // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
  k = typeof k !== 'undefined' ? k : 1 / 3;
  // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
  let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
  let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
  // 计算高亮效果的放大比例（未高亮，则比例为 1）
  let hoverRate = isHovered ? 1.05 : 1;
  // 返回曲面参数方程
  return {
    u: {
      min: -Math.PI,
      max: Math.PI * 3,
      step: Math.PI / 32
    },
    v: {
      min: 0,
      max: Math.PI * 2,
      step: Math.PI / 20
    },
    x: function (u, v) {
      if (u < startRadian) {
        return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian) {
        return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
    },
    y: function (u, v) {
      if (u < startRadian) {
        return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      if (u > endRadian) {
        return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
      }
      return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
    },
    z: function (u, v) {
      if (u < - Math.PI * 0.5) {
        return Math.sin(u);
      }
      if (u > Math.PI * 2.5) {
        return Math.sin(u);
      }
      return Math.sin(v) > 0 ? 1 * height : -1;
    }
  };
};

// 生成模拟 3D 饼图的配置项
function getPie3D(pieData, internalDiameterRatio) {
  let series = [];
  let sumValue = 0;
  let startValue = 0;
  let endValue = 0;
  let legendData = [];
  let k = typeof internalDiameterRatio !== 'undefined' ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio) : 1 / 3;
  // 为每一个饼图数据，生成一个 series-surface 配置
  for (let i = 0; i < pieData.length; i++) {

    sumValue += pieData[i].value;

    let seriesItem = {
      name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
      type: 'surface',
      parametric: true,
      wireframe: {
        show: false
      },
      pieData: pieData[i],
      pieStatus: {
        selected: false,
        hovered: false,
        k: k
      }
    };

    if (typeof pieData[i].itemStyle != 'undefined') {
      let itemStyle = {};
      typeof pieData[i].itemStyle.color != 'undefined' ? itemStyle.color = pieData[i].itemStyle.color : null;
      typeof pieData[i].itemStyle.opacity != 'undefined' ? itemStyle.opacity = pieData[i].itemStyle.opacity : null;
      seriesItem.itemStyle = itemStyle;
    }
    series.push(seriesItem);
  }
  // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
  // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
  for (let i = 0; i < series.length; i++) {
    endValue = startValue + series[i].pieData.value;
    console.log(series[i]);
    series[i].pieData.startRatio = startValue / sumValue;
    series[i].pieData.endRatio = endValue / sumValue;
    series[i].parametricEquation = getParametricEquation(series[i].pieData.startRatio, series[i].pieData.endRatio, false, false, k, series[i].pieData.value);
    startValue = endValue;
    legendData.push(series[i].name);
  }
  return series;
}


const setOption = (data) => {
  // 传入数据生成 option
  const optionsData = [
    {
      prop: '16-30',
      name: '16-30岁',
      value: 0,
      itemStyle: {
        color: '#19EBFF',
      },
    },
    {
      prop: '31-45',
      name: '31-45岁',
      value: 0,
      itemStyle: {
        color: '#3C38FF',
      },
    },
    {
      prop: '46-56',
      name: '46-56岁',
      value: 0,
      itemStyle: {
        color: '#E5751C',
      },
    },
    {
      prop: '57-65',
      name: '57-65岁',
      value: 0,
      itemStyle: {
        color: '#387EFF',
      },
    },
  ];
  let total = 0
  let big = -1
  optionsData.forEach(i => {
    for (const key in data) {
      if (i.prop === key) {
        i.value = data[key] || 0
        total += data[key]
        if (i.value > big) {
          big = i.value
        }
      }
    }
  })

  // 3d饼图高度的折算基数
  let bigStr = big.toString()
  let bigLimit = '1'
  for (let i = 0; i < bigStr.length; i++) {
    bigLimit += '0'
  }

  let suffix = '1'
  for (let i = 0; i < bigStr.length-2; i++) {
    suffix += '0'
  }
  if (big > bigLimit / 2) {
    suffix += '0'
  }

  const series = getPie3D(optionsData, 0.8, 240, 28, 26, 0.5);

  series.push({
    name: 'pie2d',
    type: 'pie',
    roseType: true,
    label: {
      opacity: 1,
      // backgroundColor: '#fff',
      borderRadius: 3,
      height: 0,
      width: 0,
      padding: [3, -3, 3, -3],
      formatter: function (params) {
        let data = params.data
        if (params.value === 0) {
          data.labelLine = {}
          data.label = {}
          data.labelLine.show = false
          data.label.show = false
        } else {
          return `{d|${params.percent}%}`
        }
      },
      rich: {
        d: {
          fontWeight: 'bold',
          fontFamily: 'youSheBiaoTiHei',
          fontSize: 20,
          padding: [-20, -80, 0, -80],
          color: '#fff'
        }
      }
    },
    labelLine: {
      show: true,
      length: 50,
      length2: 80,
      lineStyle: {
        cap: 'round'
      }
    },
    borderRadius: 2.5,
    // labelLayout: {
    //   verticalAlign: 'bottom'
    // },
    startAngle: -50, //起始角度，支持范围[0, 360]。
    clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
    radius: ['50%', '50%'],
    center: ['50%', '39%'],
    data: optionsData,
    itemStyle: {
      opacity: 0,
    },
  });

  let option = {
    legend: {
      tooltip: {
        show: true,
      },
      data: ['16-30岁', '31-45岁', '46-56岁', '57-65岁'],
      bottom: '5%',
      textStyle: {
        color: '#fff',
        fontSize: 16,
      },
    },
    animation: true,
    tooltip: {
      formatter: params => {
        if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
          return `${params.seriesName}<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>${option.series[params.seriesIndex].pieData.value}`;
        }
      },
      textStyle: {
        fontSize: 20
      },
    },
    title: {
      x: 'center',
      top: '20',
      textStyle: {
        color: '#fff',
        fontWeight: 'normal',
        fontSize: 16
      },
      text: '在职人数',
      subtext: total,
      top: '32%',
      subtextStyle: {
        color: '#fff',
        fontFamily: 'youSheBiaoTiHei',
        fontSize: 24,
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
    },
    // backgroundColor: '#020F1E',
    labelLine: {
      show: true,
      lineStyle: {
        color: '#fff',
      },
    },
    label: {
      show: true,
      position: 'outside',
      formatter: '{b} \n{c} {d}%',
    },
    xAxis3D: {
      min: -1,
      max: 1,
    },
    yAxis3D: {
      min: -1,
      max: 1,
    },
    zAxis3D: {
      min: -1,
      max: 1,
    },
    grid3D: {
      show: false,
      boxHeight: 1 / suffix,
      top: '-12%',
      // environment: '#020F1E',
      viewControl: {
        distance: 200,
        alpha: 50,
        beta: 60,
        rotateSensitivity: 0,
        zoomSensitivity: 0,
        panSensitivity: 0,
        autoRotate: false, // 自动旋转
      },
      axisLabel: {
        show: true
      }
    },
    series: series,
  };
  // console.log(444444, option);

  myChart.setOption(option);

}

const initChart = () => {
  myChart = echarts.init(document.querySelector('#main'));
}

const getData = () => {
  ageAnalyse({}, {
    loading: true
  }).then(res => {
    setOption(res)
  })
}

defineExpose({
  getData
})

onMounted(() => {
  initChart()
  getData()
});
</script>
<style scoped lang="stylus">
.left_boottom_wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #fff;
}
@font-face {
    font-family: youSheBiaoTiHei;
    src: url('../../../assets/font/youSheBiaoTiHei.ttf') format('truetype');
}
.fm-youshe {
    font-family youSheBiaoTiHei
}
</style>
